<template>
  <div class="notifications-page">
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          disable-search
          paginated
          :endpoint="endpoints.notificationsViewSet"
          ref="notifications-smart-list"
          @updateAllItemsList="(l) => (allNotifications = l)"
        >
          <template v-slot:list-item="{ item: notification }">
            <v-list-item @click="handleClick(notification)">
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{
                  notification.title
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  notification.body
                }}</v-list-item-subtitle>
                <v-list-item-subtitle class="text-caption"
                  ><span
                    v-if="
                      moment(notification.created).isBefore(
                        moment().subtract(1, 'days')
                      )
                    "
                    >{{ moment(notification.created).format("LLL") }}</span
                  ><span v-else>{{
                    moment(notification.created).fromNow()
                  }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-chip
                  v-if="notification.feature_name"
                  small
                  color="green lighten-4"
                  >{{ notification.feature_name }}</v-chip
                >
              </v-list-item-icon>
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
    <confirmation-dialog
      title="Notification"
      :description="dialog.notification ? dialog.notification.description : ''"
      success-text="OK"
      :failure-text="null"
      :visible="dialog.showExpandedNotification"
      @successCallback="
        dialog.showExpandedNotification = false;
        dialog.notification = null;
      "
      @failureCallback="
        dialog.showExpandedNotification = false;
        dialog.notification = null;
      "
    >
      <div v-if="dialog.notification" class="mx-6">
        <h4>{{ dialog.notification.title }}</h4>
        <p>{{ dialog.notification.body }}</p>
        <p class="text-caption">
          {{ moment(dialog.notification.created).format("LLL") }}
        </p>
      </div>
    </confirmation-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Mixins from "@utils/mixins";
import moment from "moment";
import IntractSmartList from "@components/generics/IntractSmartList";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import EventBus from "@utils/event_bus";
export default {
  name: "Notifications",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractSmartList,
    ConfirmationDialog,
  },
  data() {
    return {
      moment,
      console,
      allNotifications: [],
      refreshPending: false,
      dialog: {
        showExpandedNotification: false,
        notification: null,
      },
      //   todaysNotifications: [],
      //   previousNotifications: [],
    };
  },
  computed: {
    ...mapGetters(["newNotificationsCount"]),
  },
  methods: {
    ...mapActions(["setLastNotificationId"]),
    async handleClick(notification) {
      if (notification.navigator) {
        this.$router.push(JSON.parse(notification.navigator));
        return;
      }
      // if navigator not there (old notifications) or informative notifications
      this.dialog.notification = notification;
      this.dialog.showExpandedNotification = true;
    },
    // async getTodaysNotifications() {},
    async onRefresh() {
      var listEl = this.$refs["notifications-smart-list"];
      if (listEl) listEl.getItems();
      this.refreshPending = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      EventBus.$emit("keep_alive__destroy_view", "Notifications");
    }
    next();
  },
  created() {
    console.log("new notifications count: ", this.newNotificationsCount);
  },
  watch: {
    allNotifications() {
      if (!this.allNotifications.length) return;
      this.setLastNotificationId(this.allNotifications[0].id);
    },
  },
};
</script>